import '@docsly/react/dist/index.css';

import { usePathname } from 'next/navigation';

import { useAuth } from '@clerk/nextjs';
import Docsly from '@docsly/react';

export default function DocslyClient() {
  const pathname = usePathname();

  const { getToken } = useAuth();

  const getUserToken = async () => {
    const token = await getToken({ template: 'docsly' });
    if (!token) return '';
    return token;
  };

  return (
    <Docsly
      publicId="<copy-from-dashboard>"
      pathname={pathname as string}
      getUserToken={getUserToken}
    />
  );
}
