import Link from 'next/link';
import classNames from 'classnames';
import {
  BriefcaseIcon,
  OfficeBuildingIcon,
  NewspaperIcon,
  UserGroupIcon
} from '@heroicons/react/solid';
import { ChevronIcon } from '../../common/icons';
import Click from './click';
import Hover from './hover';
import { URLS } from '../../../lib/constants';
import { analytics } from 'pages';
import { rudderTrack } from '@hooks/rudderTrack';

const { company, careers, blog, terms, customers } = URLS;

const byMarket = [
  {
    name: 'About',
    description: 'Learn more about Clerk and our mission',
    href: company,
    icon: OfficeBuildingIcon
  },
  {
    name: 'Customers',
    description:
      'Learn how our customers have implemented Clerk into their product.',
    href: customers,
    icon: UserGroupIcon
  },
  {
    name: 'Careers',
    description: 'Explore opportunities at Clerk',
    href: careers,
    icon: BriefcaseIcon
  },
  {
    name: 'Blog',
    description: 'Read the latest thoughts from our team',
    href: blog,
    icon: NewspaperIcon
  }
];

function ListItem({ item }) {
  const Icon = item.icon;

  if (item.comingSoon) {
    return (
      <li className="flow-root">
        <div>
          <span className="flex px-3 py-2 -mx-3 text-sm font-medium text-gray-500">
            {item.name}
            <span className="ml-2 rounded-full bg-blue-50 px-2.5 py-0.5 text-xs font-medium text-clerk-blue tracking-wide">
              Coming soon
            </span>
          </span>
        </div>
      </li>
    );
  }

  return (
    <li className="flow-root">
      <Link legacyBehavior href={item.href}>
        <a className="flex px-3 py-2 pr-20 -mx-3 text-sm font-medium text-gray-900 transition duration-150 ease-in-out rounded hover:bg-gray-menu-item-hover">
          {Icon && <Icon className="w-5 h-5 mr-2 text-gray-400" />}
          {item.name}
        </a>
      </Link>
    </li>
  );
}

export default function Company({ textColor }) {
  return (
    <>
      <Hover
        Button={() => (
          <button
            type="button"
            className={classNames(
              textColor,
              'py-4 group items-center text-md font-medium focus:outline-none hidden lg:inline-flex'
            )}
          >
            Company
          </button>
        )}
        popup={
          // lg:max-w-xl
          <div className="absolute z-10 max-w-md px-2 transform -translate-x-1/2 w-max left-1/2 sm:px-0">
            <div className="overflow-hidden shadow-lg rounded-2xl ring-1 ring-black ring-opacity-5">
              <div className="relative gap-8 px-8 py-6 bg-white p">
                <p className="mb-5 text-lg font-semibold text-black">Company</p>

                <div className="grid grid-cols-1 gap-6">
                  <ul>
                    {byMarket.map(item => (
                      <li key={item.name} className="flow-root">
                        <Link legacyBehavior href={item.href}>
                          <a className="flex items-start px-3 py-2 pr-20 -mx-3 transition duration-150 ease-in-out rounded hover:bg-gray-menu-item-hover">
                            {item.icon({
                              className: 'flex-shrink-0 w-5 h-5 text-gray-400',
                              'aria-hidden': 'true'
                            })}
                            <div className="ml-2">
                              <p className="mb-1 text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="text-xs font-normal text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Click
        Button={({ open }) => (
          <button
            type="button"
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'w-full group inline-flex justify-between items-center text-base font-medium hover:text-gray-900 focus:outline-none lg:hidden'
            )}
          >
            Company
            <ChevronIcon
              className={classNames(
                open
                  ? 'fill-current transform rotate-0'
                  : 'fill-current transform -rotate-90',
                'flex-shrink-0 w-6 h-6 ml-2 group-hover:fill-current'
              )}
            />
          </button>
        )}
        popup={
          <div className="w-full px-2 transform sm:px-0">
            <div>
              <div className="grid grid-cols-1 gap-20 sm:grid-cols-2">
                <div className="w-full">
                  <ul className="mt-2">
                    {byMarket.map(({ icon, name, href }) => (
                      <ListItem
                        key={name}
                        item={{
                          icon,
                          name,
                          href
                        }}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
